/* SECTION: PAGE CONTACT
-------------------------------------------- */

.ptf-page--contact {
  // background-image: url(../../img/root/contact-bubble.png);
  background-repeat: no-repeat;
  background-position: 100% 130px;
}
/* SECTION: PAGE CONTACT Video
-------------------------------------------- */

.ptf-page-video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.ptf-page-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media queries for additional responsiveness */
@media (max-width: 768px) {
  .video-container {
    padding-bottom: 75%; /* Increase aspect ratio for smaller screens */
  }
}

/* SECTION: PAGE SERVICE DETAIL
-------------------------------------------- */

.ptf-page--service-detail {
  .ptf-skill-box-grid {
    display: grid;

    grid-template-columns: repeat(5, 1fr);
    grid-gap: px2rem(20px);
    @include mq("large") {
      grid-template-columns: repeat(4, 1fr);
    }
    @include mq("medium") {
      grid-template-columns: repeat(3, 1fr);
    }
    @include mq("small") {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

/* SECTION: PAGE BLOG MASONRY
-------------------------------------------- */

.ptf-page--blog-masonry .container-xxl--custom {
  @media (min-width: 1580px) {
    max-width: 100% !important;
    padding: 0 px2rem(60px);
  }
}

/* SECTION: PAGE ABOUT US
-------------------------------------------- */

.ptf-page--about-us section:first-of-type {
  background-image: url(../../img/root/about-us-bubble.png);
  background-repeat: no-repeat;
  background-position: calc(50% + 200px) calc(100% + 50px);
}

/* SECTION: PAGE ABOUT ME
-------------------------------------------- */

.ptf-page--about-me .ptf-custom--5512,
.ptf-page--home-freelancer .ptf-custom--5512 {
  position: relative;

  display: inline-block;

  padding-right: px2rem(30px);

  &::after {
    content: "";

    position: absolute;
    right: px2rem(-120px);
    bottom: 0;
    z-index: -1;

    width: px2rem(387px);
    height: px2rem(460px);

    background-image: url(../../img/root/about-me-bubble.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.ptf-page--home-freelancer .ptf-custom--5512 {
  padding-right: 0;
}

.ptf-page--about-me .ptf-custom--1568 {
  background-image: url(../../img/root/about-me-bubble-2.png),
    url(../../img/root/about-me-bubble-3.png);
  background-repeat: no-repeat, no-repeat;
  background-position: 0 calc(50% - 50px), 100% calc(50% + 250px);
}

/* SECTION: HOME VERTICAL
-------------------------------------------- */

.ptf-is--home-vertical {
  body,
  .ptf-site-wrapper__inner {
    background-color: var(--ptf-color-19);
  }
}

/* SECTION: HOME MODERN / MINIMAL
-------------------------------------------- */

.ptf-is--home-modern,
.ptf-is--home-minimal {
  body,
  .ptf-site-wrapper__inner {
    background-color: var(--ptf-color-11);
  }
}

.ptf-is--home-modern {
  .ptf-navbar,
  .ptf-navbar.ptf-navbar--solid {
    background-color: var(--ptf-color-11);
  }
}

.ptf-is--home-modern section:first-of-type {
  background-image: url(../../img/root/home-modern-bubble.png);
  background-repeat: no-repeat;
  background-position: calc(50% + 100px) calc(100% + 50px);
}

.ptf-page--home-modern {
  .ptf-work__title {
    font-weight: normal;
  }

  .ptf-post--style-1 .ptf-post__meta {
    text-transform: none;

    span.cat {
      font-weight: 600;
    }
  }

  .ptf-post--style-1 .ptf-post__title {
    font-size: px2rem(30px);
    font-weight: normal;
  }
}

/* SECTION: HOME TRENDING
-------------------------------------------- */

.ptf-page--home-trending {
  background-image: url(../../img/root/home-trending-bubbles.png);
  background-repeat: no-repeat;
  background-position: 50% 250px;
  background-size: contain;
}

/* SECTION: HOME DEFAULT
-------------------------------------------- */

.ptf-is--home-default {
  .ptf-navbar,
  .ptf-navbar.ptf-navbar--solid {
    background-color: var(--ptf-accent-2);
  }

  .ptf-nav--default .sf-menu > li.current-menu-ancestor > a,
  .ptf-nav--default .sf-menu > li.current-menu-item > a,
  .ptf-nav--default .sf-menu > li.sfHover > a,
  .ptf-nav--default .sf-menu > li:hover > a {
    color: var(--ptf-color-white);
  }

  section:first-of-type {
    background-image: url(../../img/home/default/main-image.png);
    background-repeat: no-repeat;
    background-position: calc(50vw - 100px) calc(50% + 150px);
  }
}

/* SECTION: HOME STUDIO
-------------------------------------------- */

.ptf-is--home-studio {
  section:first-of-type {
    background-image: url(../../img/root/home-studio-bubble-1.png);
    background-repeat: no-repeat;
    background-position: 100% 200px;
  }

  section.ptf-custom--1562 {
    background-image: url(../../img/root/home-studio-bubble-2.png);
    background-repeat: no-repeat;
    background-position: 0 100%;
  }
}

/* SECTION: HOME FREELANCER
-------------------------------------------- */

.ptf-is--home-freelancer {
  .ptf-custom--1528 {
    background-image: url(../../img/root/home-freelancer-bubble-1.png),
      url(../../img/root/home-freelancer-bubble-2.png);
    background-repeat: no-repeat, no-repeat;
    background-position: 0 650px, 100% 65%;
  }

  .ptf-counter-up--style-2 .ptf-counter-up__value {
    font-size: px2rem(72px);
  }

  .ptf-work--style-3 {
    .ptf-work__category {
      text-transform: none;
    }

    .ptf-work__title {
      font-weight: normal;
    }
  }

  section:last-of-type {
    background-image: url(../../img/root/call-to-action.png);
    background-repeat: no-repeat;
    background-position: calc(50% + 30px) calc(50% + 30px);
    @media (max-width: 575px) {
      background-image: none;
    }
  }
}

/* SECTION: HOME DARK
-------------------------------------------- */

.ptf-is--home-dark {
  body,
  .ptf-site-wrapper__inner {
    background-color: var(--ptf-color-12);
  }

  .ptf-work--style-3 {
    .ptf-work__category {
      text-transform: none;
      color: var(--ptf-color-white);
    }

    .ptf-work__title {
      color: var(--ptf-color-white);
      font-weight: normal;
    }
  }

  .ptf-custom--1734 {
    position: absolute;
    top: calc(50% - 190px);
    left: -146px;
    z-index: 3;
  }

  .ptf-custom--1772 {
    background-image: url(../../img/root/home-dark-bubble-2.png);
    background-repeat: no-repeat;
    background-position: 100% 150px;
  }

  .ptf-custom--1674 {
    background-image: url(../../img/root/home-dark-bubble-1.png);
    background-repeat: no-repeat;
    background-position: 0 250px;
  }

  .ptf-custom--1560 {
    position: relative;

    display: inline-block;

    &::after {
      content: "";

      position: absolute;
      right: px2rem(-120px);
      bottom: px2rem(200px);
      z-index: -1;

      width: px2rem(254px);
      height: px2rem(192px);

      background-image: url(../../img/root/home-dark-waves.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

/* SECTION: HOME AGENCY
-------------------------------------------- */

.ptf-is--home-agency {
  .ptf-custom--home-inspection {
    position: relative;
    background-image: url(../../img/home/agency/home-inspection.png);
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
      content: "";

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      width: calc(50% - #{px2rem(250px)});

      background-color: var(--ptf-color-white);

      @include mq("large") {
        width: 100%;
      }
    }
  }

  .ptf-custom--3993 {
    position: relative;
    background-image: url(../../img/home/agency/about-image.png);
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
      content: "";

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      width: calc(50% - #{px2rem(250px)});

      background-color: var(--ptf-color-white);

      @include mq("large") {
        width: 100%;
      }
    }
  }

  .ptf-custom--3994 {
    position: relative;
    background-image: url(../../img/home/agency/why-bnt.png);
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
      content: "";

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      width: calc(50% - #{px2rem(250px)});

      background-color: var(--ptf-color-white);

      @include mq("large") {
        width: 100%;
      }
    }
  }

  .ptf-custom--3995 {
    position: relative;
    background-image: url(../../img/home/agency/diy-image.png);
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
      content: "";

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      width: calc(50% - #{px2rem(250px)});

      background-color: var(--ptf-color-white);

      @include mq("large") {
        width: 100%;
      }
    }
  }

  .ptf-custom--1569 {
    background-color: var(--ptf-color-8);
    background-image: url(../../img/root/home-agency-symbol.png);
    background-repeat: no-repeat;
    background-position: calc(100% + 15px) calc(100% + 150px);
  }
}
.ptf-btn.ptf-btn--underlined {
  font-size: 1rem;
  padding: 0.5625rem 0;
  color: var(--ptf-color-black);
  -webkit-box-shadow: inset 0 -2px currentColor;
  box-shadow: inset 0 -2px currentColor;
}
.ptf-twitter-review__content span {
  color: #0cf;
}
.scroll-top {
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 20px;
  left: 5px;
  z-index: 99;
  text-align: center;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  background: #000;
  transition: all 0.3s ease-in-out;
  border: none;
  outline: none;
  &::after {
    position: absolute;
    z-index: -1;
    content: "";
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.25) 0,
      transparent 80%
    );
  }
}

.testimonial-100-spacer {
  .slick-slider {
    margin: 0 -100px;
    padding-bottom: 60px;
    @media (max-width: 991px) {
      margin: 0 -30px;
    }
  }
  .slick-initialized .slick-slide {
    padding: 0 100px;
    @media (max-width: 991px) {
      padding: 0 30px;
    }
  }
  .slick-prev,
  .slick-next {
    top: 50%;
  }
}
.invalid-feedback {
  font-size: 1rem;
}
.agreement-checkbox
  input[type="checkbox"]:checked
  + label
  .ptf-checkbox__checkmark {
  background-color: var(--ptf-accent-1);
  -webkit-box-shadow: inset 0 0 0 1px var(--ptf-accent-1);
  box-shadow: inset 0 0 0 1px var(--ptf-accent-1);
}

.agreement-checkbox
  input[type="checkbox"]:checked
  + label
  .ptf-checkbox__checkmark::after {
  display: block;
}

.agreement-checkbox input[type="checkbox"] {
  display: none;
  position: absolute;
  left: -100%;
  top: 50%;
}
.contact_form {
  input {
    font-size: 2.5rem !important;
  }
}

@media (max-width: 1100px) {
  .ptf-is--home-agency .ptf-custom--3993 {
    background-position: -378px;
  }
}
@media (max-width: 991px) {
  .ptf-is--home-agency .ptf-custom--3993 {
    background-image: none;
  }
}

@media (max-width: 1100px) {
  .ptf-is--home-agency .ptf-custom--3994 {
    background-position: -378px;
  }
}
@media (max-width: 991px) {
  .ptf-is--home-agency .ptf-custom--3994 {
    background-image: none;
  }
}


@media (max-width: 1100px) {
  .ptf-is--home-agency .ptf-custom--3995 {
    background-position: -378px;
  }
}
@media (max-width: 991px) {
  .ptf-is--home-agency .ptf-custom--3995 {
    background-image: none;
  }
}

